<script setup lang="ts">
    import CpProductCard from '~/components/cp/CpProductCard/CpProductCard.vue';
    import type {IEtaInfo} from '~/types';

    const props = withDefaults (defineProps<{
      kind: 'FeaturedOffer'|'Standard'|'Offer'|'Featured'|'Oos'|'OosOffer',
      productType: 'physical'|'esd',
      pillLarge: boolean,
      pillShort: boolean,
      rowLarge?: boolean,
      rowShort?: boolean,
      rate: boolean,
      rateValue: number,
      reviews: number,
      freeShipping: boolean,
      link: string,
      title: string,
      hasDiscount: boolean,
      discount: string | boolean,
      picture: string,
      imageTitle: string,
      price: number,
      tPrice: number,
      id: string,
      eta: boolean,
      dateStart?: number,
      dateEnd?: number,
      etaData?: IEtaInfo | { messages: IEtaInfo['messages'] }
    }>(), {
      eta: true,
    });
</script>

<template>
    <div class="cp-product-card-catalog">
        <CpProductCard v-bind="props" />
    </div>
</template>

<style scoped lang="scss">
    .cp-product-card-catalog {
      border: 1px solid var(--color-neutral-extra-light);
      border-radius: 8px;
      width: calc(50% - 8px);
      height: 328px;
    }
</style>
